<template>
  <l-default>
    <OTitle>Bitcoin Pay</OTitle>
    <OCRow class="u-mb-3">
      <div>{{email}}</div>
      <div class="u-ml-auto">{{credits}} credits</div>
    </OCRow>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive">
      <CBuyButton :credits="Number(parameters.credits)" :cost="Number(parameters.amount)"
                  :always="isAlways" :isLink="false" class="c-grid__item"></CBuyButton>

      <div class="c-grid__item">
        Send <span style='color:#ff0000;font-size: 180%'><strong>EXACTLY</strong></span> {{parameters.btc}} BTC to:
      </div>

      <div class="c-grid__item">
        <input type="text" v-model="btcAddress" readonly="readonly">
        <button class="copy-button" type="button" @click="doCopy"></button>
      </div>

      <div class="c-grid__item">
        <img
          v-bind:src="'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=bitcoin:'+parameters.address+'?amount='+parameters.btc"
          height="130" weight="130"/>
      </div>

      <div class="c-grid__item">
        If you have bitcoin wallet installed:<br/>
        <a v-bind:href="'bitcoin:'+parameters.address+'?amount='+parameters.btc">Pay With Wallet</a>
      </div>
    </div>

  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters } from 'vuex'
import OCRow from '../../objects/containers/OCRow'
import CBuyButton from '../../components/CBuyButton'

export default {
  name: 'PageBitcoinPay',
  components: {
    LDefault,
    OTitle,
    CBuyButton,
    OCRow
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      credits: 'getCredits',
      email: 'getEmail',
      isActive: 'isActive',
      phones: 'getPhones'
    }),
    parameters: function () {
      return this.$route.query
    },
    isAlways: function () {
      return false
    }
  },
  data: () => ({ showError: false, btcAddress: null }),
  mounted () {
    this.btcAddress = this.parameters.address
    this.showError = !this.isAlways && this.phones.length === 0
  },
  methods: {
    doCopy () {
      this.$copyText(this.btcAddress)
    }
  }
}
</script>

<style lang="scss">
  .copy-button {
    &:before {
      display: none;
      content: 'Copy';
    }

    &:after {
      content: 'Copy';
    }

    &:active, &:focus {
      outline: none;

      &:after {
        content: 'Copied!';
      }
    }
  }

  .pay-button {
    background: $primary;
    color: #fff;
    font-size: 1.15em;
    border-radius: 4px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    max-width: 750px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: none;
    vertical-align: top;
    cursor: pointer;
    overflow: hidden;
  }

  .c-grid {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 0 1rem;
  }

  .c-grid__title {
    margin: 0;
    padding: 0.25em 0;
    text-transform: uppercase;
  }

  .c-grid__item {
    margin: 0 0 0.5rem;
  }

  .u-ml-auto {
    margin-left: auto;
  }

  .u-mb-3 {
    margin-bottom: 1.5rem;
  }
</style>
