<template>
  <div class="o-row"><slot></slot></div>
</template>

<script>
export default {
  name: 'OCRow'
}
</script>

<style lang="scss">
  .o-row {
    display: flex;
    flex-flow: row nowrap;
  }
</style>
