<template>
  <button :class="classes" :type="type" v-on="$listeners" :is="component" :to="to" :disabled="disabled"><slot></slot></button>
</template>

<script>
export default {
  name: 'OButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    },
    theme: {
      type: String,
      validator: value => ['primary', 'radio', 'outline-primary', 'vradio', 'none', 'notice'].includes(value),
      default: 'primary'
    },
    type: {
      type: String,
      default: 'button'
    },
    to: {
      type: Object
    }
  },
  computed: {
    classes () {
      return ['o-btn', `o-btn--${this.theme}`]
    },
    component () {
      return this.to ? 'router-link' : this.tag
    }
  }
}
</script>

<style lang="scss">
  .o-btn {
    @include typi('button');
    display: block;
    font-family: inherit;
    font-weight: inherit;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: vr(0.75);
    text-transform: uppercase;
    cursor: pointer;
    margin: 0;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .o-btn--notice {
    background-color: #b9e9d3;
    color: #0d6e41;
  }

  .o-btn--none {
    background-color: transparent;
    color: $primary;
  }

  .o-btn--primary {
    background-color: $primary;
    color: #fff;
  }

  .o-btn--outline-primary {
    background-color: transparent;
    color: $primary;
    border-color: $primary;
  }

  .o-btn--radio,
  .o-btn--vradio {
    background-color: #fff;
    color: $primary;
    border: 1px solid $primary;

    &[aria-checked=true] {
      background-color: $primary;
      color: #fff;
    }
  }

  .o-btn--radio {
    border-right-color: transparent;

    &:last-child {
      border-right-color: $primary;
    }
  }

  .o-btn--vradio {
    border-bottom-color: transparent;

    &:last-child {
      border-bottom-color: $primary;
    }
  }
</style>
